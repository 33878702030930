function changeFavicon(src) {
    var link = document.createElement('link'),
        oldLink = document.getElementById('dynamic-favicon');
    link.id = 'dynamic-favicon';
    link.rel = 'shortcut icon';
    link.href = src;
    if (oldLink) {
     document.head.removeChild(oldLink);
    }
    document.head.appendChild(link);
   }


export default {
    state: {
        data : null
     },
    async load() {
        await this.services.http.get("/items/Page_Settings")
            .then( (response) => {
                    this.update_store( response.data );
                    document.title = this.state.data.page_title;
                     changeFavicon( this.services.images.public_url(this.state.data.Icon));
                    })
            .catch( (error) => this.services.error.push(error));
        
    }
}