<template>
  <div>
      <action-header :back="false" :title="this.$route.name"></action-header>
      <div class="my-16 align-center"  style="width: 100%;">
        <v-card style="max-width: 500px;" class="ma-auto pa-6">
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field type="password" v-model="password" label="Password" :rules="[v => (v && v.length >= 6) || 'Password must be at least 6 characters.', ]"></v-text-field>
                <v-text-field type="password" v-model="repeat_password" :rules="[ v => v == password || 'Passwords do not match']" label="Repeat Password"></v-text-field>
                <v-btn @click="reset_password" block :disabled="!valid" color="success">Accept</v-btn>
            </v-form>
        </v-card>
      </div>
  </div>
</template>

<script>
import ActionHeader from '../components/ActionHeader.vue'
export default {
  components: { ActionHeader },
    data : () => ( {
        valid       : false,
        password    : '',
        repeat_password: '',
    }),
    methods: {
        async reset_password() {
            await this.$service.user.accept_invite( this.$route.query.token, this.password );
             this.$service.overlay.open_overlay( {
                                title: "Invite Accepted",
                                message: "You can now log in to your account with your new password!"
                            });
            this.$router.push("/login");
           
        } 
    }
}
</script>

<style>

</style>