export default {
    state: {
        user_data: null,
        valid : false,
        require_otp : false,
    },
    token : '',
    timer : null,

    async set_token( token ) {
        this.state.valid = true;
        this.state.require_otp = false;
        this.services.http.set_auth( token );
        this.token = token;
        await this.services.initital.auth_init();
        
    },
    update( payload ) {
        this.services.http.patch("/users/me", payload);
    },
    async load_user_data() {
        await this.services.http.get("/users/me?fields=tfa_secret,role.app_access,repository")
            .then( response => this.state.user_data = response.data.data )
            .catch( error => this.services.error.push(error));
        
    },
    check_tfa(){
        if( !this.state.user_data.tfa_secret) {
            this.services.$router.push( "/activatetfa");
            return false;
        } 
        else if(this.services.$router.route == "/activatetfa") {
            this.services.$router.push("/home");
        }
        return true;
    },
    async refresh() {
        this.state.valid = false;
        await this.services.http.post( "/auth/refresh" , { mode : "cookie"} )
            .then( async response => await this.set_token( response.data.data.access_token ))
            
        this.state.valid = true;
        setTimeout(() => this.refresh() , 1000 * 14 * 60)
    },
    async auth( payload ) {
        payload.mode = "cookie";
        await this.services.http.post( "/auth/login", payload)
            .then( response => this.set_token( response.data.data.access_token ))
            .catch( error => this.handle_auth_error(error));
        
    },
    handle_auth_error(error) {
        if (error.response.data.errors[0].extensions.code == "INVALID_OTP" && !this.state.require_otp) { 
            this.state.require_otp = true;
            return; }
        
        throw error;
    },
    async logout() {        
        await this.services.http.post("/auth/logout", );
        this.services.$router.push("/login");
        this.state.valid = false;
    },
    async request_password_reset( email ) {
        await this.services.http.post(  "/auth/password/request", 
                                        {"email" : email,
                                        "reset_url" : `${this.services.http.defaults.baseURL}/reset-password`})
            .catch( error => this.services.error.push( error ));
    },
    async reset_password(token, new_password ) {
        await this.services.http.post(  "/auth/password/reset", 
                                        {"token" : token,
                                        "password" : new_password})
            .catch( error => this.services.error.push( error ));
    },
    async accept_invite(token, new_password ) {
        await this.services.http.post(  "/users/invite/accept", 
                                        {"token" : token,
                                        "password" : new_password})
            .catch( error => this.services.error.push( error ));
    },
    async enable_tfa( otp, secret ) {
        await this.services.http.post("/users/me/tfa/enable", { otp :otp , secret: secret});
    }
    
}