export default {
    state: {
        data: { 
            projector : false,
            mode : "Disconnected",
            health : null,       
            now_playing : null,
            up_next : 0,
            time_end : "11:00"
        }
        
    },
    async load() {
        console.log("Loading Status");
        this.services.http.get( "items/Status?fields=projector, mode,health,now_playing,up_next,time_end" )
            .then( response => this.state.data = response.data.data )
            .catch( error => this.services.error.push(error) );
    },
}