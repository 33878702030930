<template>
  <v-app>
    <promt-message-overlay></promt-message-overlay>
      <v-main v-if="init">
          <v-container class="d-flex justify-center" style="min-height: 100%; max-width: 1100px;" >
            <router-view>
            </router-view>

          </v-container>
      </v-main>
      <loading v-else/>
      <v-footer dark v-if="$service.pagesettings.state.data" style="height: 70px;">
        {{$service.pagesettings.state.data.footer_text_left}}
        <v-spacer></v-spacer>
        {{$service.pagesettings.state.data.footer_text_right}}
      </v-footer>

  </v-app>
</template>

<script>

import PromtMessageOverlay from './components/PromtMessageOverlay.vue';
import Loading from './views/Loading.vue';




export default {
  components: { PromtMessageOverlay, Loading},
  name: 'App',
  data : () => ( {
    init : false
  }),
  async created(){

    await this.$service.pagesettings.load();
    await this.$service.user.refresh()
      .then( () => { if (this.$route.path == "/") { this.$router.push("/home"); }
                    console.log("PUshing home") } )
      .catch( () => {
          if ( !this.$service.helper.check_legal_route( this.$route.path ) ) 
            { this.$router.push("/login").catch( () => null) }
          } );
      
    
    this.init = true;
  }
};
</script>
