module.exports = {
    today_string() {
        return  new Date().toISOString().slice(0, 10);
    },
    Base_Content_Schedule() {
        return { name : "New Weekly Schedule", start : this.today_string(), actions: [
            {name : "Monday", actions:[]},
            {name : "Tuesday", actions:[]},
            {name : "Wednesday", actions:[]},
            {name : "Thursday", actions:[]},
            {name : "Friday", actions:[]},
            {name : "Saturday", actions:[]},
            {name : "Sunday", actions:[]},
        ]}
     },
     Special_Content_Schedule() {
        return { name : "New Special Schedule", start :  this.today_string(), end :  this.today_string(), actions: [
            {name : "Special", actions:[]}
        ]}
     },
}