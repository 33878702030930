<template>
    <v-container>
        <v-tabs grow v-model="selected_tab" color="black">
            <template v-if="data.actions.length > 1">
                <template v-if="!$vuetify.breakpoint.mobile">
                    <v-tab v-for="(day, index) in data.actions" :key="index+100">{{day.name.substring(0,2)}}</v-tab>
                </template>
                <template v-else>
                    <v-menu bottom left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-card style="width: 100%;"
                                    elevation = "0"
                                    class="d-flex justify-space-between" 
                                    v-bind="attrs" 
                                    v-on="on">
                                        <v-icon large>    mdi-arrow-down-drop-circle    </v-icon>
                                        <v-spacer/>
                                        <h2>{{data.actions[selected_tab].name}}</h2>
                                        <v-spacer/> 
                            </v-card>
                        </template>

                        <v-list class="grey lighten-3">
                            <v-list-item
                                v-for="(day, index) in data.actions" :key="index+100"
                                @click="selected_tab = index">
                                    {{ day.name }}
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template> 
            </template> 
               
            <v-tab-item 
                v-for="(day, index) in data.actions" 
                :key="index" >
                    <schedule-day-card :data="day" :key="index" >
                        <template>
                            <v-spacer></v-spacer>
                            <h2>        {{day.name}}   </h2>
                            <v-spacer/>
                        </template>

                    </schedule-day-card>
            </v-tab-item>
        </v-tabs>
    </v-container>        
</template>

<script>
import ScheduleDayCard from './ScheduleDayCard.vue'
export default {
  components: { ScheduleDayCard },
  props: ["data"],
    data: () => ( {
        selected_tab: 0
    }),
}
</script>

<style>
.v-application ul {
    padding-left: 0px;
}
</style>