import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home'
import Loading from '@/views/Loading'
import ErrorPage from '@/views/ErrorPage'
import Login from '@/views/Login'
import ResetPassword from '@/views/ResetPassword.vue';
import ForgotPassword from '@/views/ForgotPassword.vue';
import Help from '@/views/Help.vue';
import Schedule from '@/views/Schedule.vue';
import ActivateTfa from '@/views/ActivateTfa.vue';
import AcceptInvite from '@/views/AcceptInvite.vue';
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Entry',
    component: Loading
  },
  {
    path: '/help',
    name: 'Help',
    component: Help
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: ForgotPassword,
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    component: ResetPassword,
  },
  {
    path: '/admin/accept-invite',
    name: 'Accept Invite',
    component: AcceptInvite,
  },
  {
    path: '/:action/:endpoint/:id',
    name: 'Schedule',
    component: Schedule,
  },
  {
    path: '/activatetfa',
    name: 'Activate 2FA',
    component: ActivateTfa,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/error',
    name: "Error",
    component: ErrorPage,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
