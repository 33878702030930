<template>
      <div class="d-flex flex-wrap">
                    <health-display :status="$service.status.state.data" style="width: 300px; height: 12.5rem;"></health-display>
                    <playback-display :data="$service.status.state.data" style="width: 300px; height: 12.5rem;"></playback-display>
            
      </div>

</template>

<script>
//const io = require("socket.io-client");
import HealthDisplay from './StateDisplay/HealthDisplay.vue'
import PlaybackDisplay from './StateDisplay/PlaybackDisplay.vue'
export default {
  socket_connection : null,
  data: () => ( {
    status_data: null,
  }),

  components: { HealthDisplay, PlaybackDisplay },

}
</script>

<style>

</style>