<template>
    <div class="flex-grow-1">

        <home-header></home-header>
        <state-display      class="mx-1" ></state-display>

        <projection-times   class="mx-1">></projection-times>

        <seperator class="self-align-center"></seperator>
        <br v-if="$vuetify.breakpoint.mobile">
        <div class="d-flex schedule_display">
            <template  v-if = "!$vuetify.breakpoint.mobile">
                <base-content-schedule-viewer  endpoint="Base_Content_Schedule" title="Weekly">
                    <help-button-weekly></help-button-weekly>
                </base-content-schedule-viewer>
                            
                <base-content-schedule-viewer  endpoint="Special_Content_Schedule" title="Special" :limit_end="true">
                    <help-button-special></help-button-special>
                </base-content-schedule-viewer>
            </template>

            <template v-else>
                <v-tabs color="black" centered grow center-active>
                    <v-tab >Weekly            </v-tab>
                    <v-tab>Special    </v-tab>
                    <v-tab-item >
                        <base-content-schedule-viewer endpoint="Base_Content_Schedule" title="">
                            <help-button-weekly></help-button-weekly>
                        </base-content-schedule-viewer>
                    </v-tab-item>

                    <v-tab-item>
                        <base-content-schedule-viewer endpoint="Special_Content_Schedule" title="" :limit_end="true">
                            <help-button-special></help-button-special>
                        </base-content-schedule-viewer>
                    </v-tab-item>
                </v-tabs>
            </template>
        </div>
                   

    </div>
</template>

<script>
import BaseContentScheduleViewer from '../components/Schedule/BaseContentScheduleViewer.vue'
import ProjectionTimes from '../components/ProjectionTimes.vue'
import StateDisplay from '../components/StateDisplay.vue'
import HelpButtonWeekly from '../components/HelpButtons/HelpButtonWeekly.vue'
import HelpButtonSpecial from '../components/HelpButtons/HelpButtonSpecial.vue'
import Seperator from '../components/Seperator.vue'
import HomeHeader from '../components/Header_Comps/HomeHeader.vue'
export default {
  components: { BaseContentScheduleViewer,StateDisplay, ProjectionTimes, HelpButtonWeekly, HelpButtonSpecial, Seperator, HomeHeader },
  mounted() {
      this.$service.user.check_tfa();
  }
}
</script>

<style scoped>
.schedule_display {
    background-color : lightgrey;
    
}
</style>