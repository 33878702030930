<template>
  <v-app-bar style="    text-overflow: ellipsis;  
                        white-space: nowrap; 
                        overflow: hidden;
                        width: 100%;" 
                        elevation="0"
                        app
                        >

                <v-btn plain v-if="back" @click="click" color="black"><v-icon>mdi-arrow-left</v-icon> <span v-if="!$vuetify.breakpoint.mobile">Go back</span></v-btn>
                 <v-spacer></v-spacer>
                <h1>{{title}}</h1>
                 <v-spacer></v-spacer>
        </v-app-bar>

</template>

<script>
export default {
    props: ["title", "back", "action_handler"],
    methods: {
        click() {
            if (this.action_handler) {return this.action_handler();}
            this.$router.go(-1)
        }
    }
}
</script>

<style>

</style>