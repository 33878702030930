<template>
  <v-app-bar  :prominent="$vuetify.breakpoint.mobile" 
              elevation = "0" 
              app
              >
   
    <div class="flex-grow-1 d-flex flex-wrap align-center justify-center">

      <div class="flex-grow-1 my-2"
          :style="header_image_style"/>
          
             
      <div class="d-flex flex-grow-1 flex-wrap" style="width: 300px">
          <v-btn class="flex-grow-1  action_button" color="black" outlined @click="click_default">Default</v-btn>
          <v-btn class="flex-grow-1  mx-1 action_button" color="black" outlined @click="click_restart">Restart</v-btn>
          <v-btn class="flex-grow-1  action_button" color="black" outlined @click="click_turn_off">Turn Off</v-btn>
      </div>
    
      <div class="d-flex flex-grow-1 justify-center" style="width: 300px">
          <v-btn elevation = "0" @click="$router.push('/help')" >
              <b v-if="show_text">Help</b>
              <v-icon>mdi-lifebuoy</v-icon>
          </v-btn>
          <v-btn elevation = "0" @click="logout" >
              <b v-if="show_text">Logout</b>
              <v-icon>mdi-logout</v-icon>
          </v-btn>
          <v-btn elevation = "0" :href="$service.http.defaults.baseURL" target = "_blank" v-if="$service.user.state.user_data.role.app_access">
              <b v-if="show_text">Admin</b>
              <v-icon>mdi-view-dashboard</v-icon>
          </v-btn>
      </div>

   
    </div>
    </v-app-bar>
</template>

<script>



export default {
  components: { },
  computed: {
    show_text() { return true;},
    header_image_style() {
      return {
        height: "30px",
        "background-image" : `url(${this.$service.images.public_url(this.$service.pagesettings.state.data.Header_Image)})`,
        "background-size" : "contain",
        "background-position" : "center",
        "width" : "300px"
      }
    }
  },
  methods: {
    click_default() {
      this.$service.overlay.open_prefab_overlay( {
                name: "default_message",
                actions: [
                    {title: "Ok",
                    action: () => this.send_control_data("default")},
                    {title: "Cancel",
                    action: () => console.log( "Action Canceled" )},
                ]
            })
    },
    click_restart() {
      this.$service.overlay.open_prefab_overlay( {
                name: "restart_message",
                actions: [
                    {title: "Ok",
                    action: () => this.send_control_data("restart")},
                    {title: "Cancel",
                    action: () => console.log( "Action Canceled" )},
                ]
            })
    },
    click_turn_off() {
      this.$service.overlay.open_prefab_overlay( {
                name: "turn_off_message",
                actions: [
                    {title: "Ok",
                    action: () => this.send_control_data("shutdown")},
                    {title: "Cancel",
                    action: () => console.log( "Action Canceled" )},
                ]
            })
    },
    send_control_data: function(message) {
      this.$service.socketio.emit(message);
    },
    logout() {
            this.$service.overlay.open_prefab_overlay({
                name: "logout_message",
                actions: [
                    {title: "Logout",
                    action: () => this.$service.user.logout()},
                    {title: "Cancel",
                    action: () => console.log( "Canceled" )},
                ]
            })
        },
  }

}
</script>

<style>
    

</style>