export default {
    auth_initialised : false,
    async auth_init() {
        if (this.auth_initialised) { return; }
        await this.services.content.load();
        await this.services.installation.load();
        await this.services.status.load();
        await this.services.help.load();
        await this.services.user.load_user_data();
        await this.services.schedules.load();
        this.services.socketio.load();
        this.auth_initialised = true;
    }
}