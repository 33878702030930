export default {
    state: {
        data: {}
    },
    load() {
        this.services.http.get( "items/Installation?fields=start_time,end_time,custom_timing" )
            .then( response => this.state.data = response.data.data )
            .catch( error => this.services.error.push(error) );
    },
    update() {
        this.services.http.patch( "items/Installation", this.state.data )
            .then( response => this.state.data = response.data.data )
            .catch( error => this.services.error.push(error) );
    }
}