<template>
   <v-container style="max-width: 700px;">
       <slot></slot>
   </v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>