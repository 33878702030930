export default {
    sort_actions( action_array ) {
        const sorting_actions = action_array;
        for ( let action of sorting_actions ){
            let timing = parseFloat( action.start.replace(":", "") );
            action.sort_timing = (timing + 1200) % 2400 + 1200
        }
        sorting_actions.sort((a,b) => (a.sort_timing > b.sort_timing) ? 1 : ((b.sort_timing > a.sort_timing) ? -1 : 0)); 
        for ( let action of sorting_actions ){
            delete action.sort_timing;
        }
    },
    today_iso(offset = -12) { 
        const today = new Date();
        today.setHours( today.getHours() + offset);
        return today.toISOString().slice(0, 10);
    },
    unauthorized_route_whitelist : ["/reset-password", "/admin/accept-invite", "/login"],
    check_legal_route( route ) {
        console.log("Checking Route", route)
        return this.unauthorized_route_whitelist.includes( route );
    }
}