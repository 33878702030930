
<template>
  <div class="d-flex align-center" style="height : 100%; ">
      <v-btn v-if   ="!highlight"   @click="$emit('click')"
        :block="block"
        :style="text_styling"
        
        :large="large? true : false"
        outlined
        > 
          {{text}} 
      </v-btn>
      <v-btn v-else                 @click="$emit('click')"
        depressed
        :large="large? true : false"
        :block="block"
        :style="text_styling"

        style=" color: white; background-color: black"
      > 
          {{ highlight_text_calculation }} 
      </v-btn> 
  </div>
</template>

<script>
export default {
    props: [ "highlight", "text", "highlight_text", "large", "capitalize", "block", "fill"],
    computed: {
        highlight_text_calculation() {
            return this.highlight_text ? this.highlight_text : this.text;
        },
        text_styling() {
            return {
                "text-transform" : this.capitalize ? "capitalize" : "none", 
                "height"    : "100%",
                "width"     : this.fill ? "100%" : "",
            }
        }
    }
}
</script>

<style>

</style>