<template>
  <v-container class="d-flex align-center flex-wrap " grid-list-xs id="parent">
       <h1 class="flex-grow-1" 
           style="width: 300px;">Projector ON/OFF times</h1>

        <v-card class="d-flex align-center px-2 flex-grow-1" 
                style="width: 300px;"  outlined elevation="0">
                <div class="flex-grow-1 mx-3">Set automatically based on sunrise and sunset times.</div>
                <v-switch width="50" color="green" @change="update" v-model="timing_data.custom_timing"></v-switch>
        </v-card>
         <div class="d-flex align-center flex-grow-1"
              style="width: 180px;" v-if="timing_data.start_time">
            <time-picker  v-model="timing_data.start_time" label="On" @change="update" :disabled="timing_data.custom_timing"></time-picker>
            <time-picker  v-model="timing_data.end_time" label="Off" @change="update" :disabled="timing_data.custom_timing"></time-picker>
        </div>

     
  </v-container>
</template>

<script>
import TimePicker from './Helper/TimePicker.vue';

export default {
  components: {TimePicker },
    computed: {
        timing_data() {
            return this.$service.installation.state.data;
        }
    },
    methods: {
      update() {
        console.log("Updated Timingdata", this.timing_data);
        this.$service.installation.update();
      }
    }

}
</script>

<style>
#parent > * {
    margin: 5px;
}
</style>