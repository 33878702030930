<template>
     <div style = "width: 100%">
        <action-header :action_handler="go_back" back="true" :title="$route.params.action.toUpperCase()"></action-header>
        <loading v-if="loading"/>
        <default-container v-if="data && !loading">
            <v-container >
                <div class="d-flex flex-wrap">
                    <v-text-field class="ma-1 flex-grow-1" v-model="data.name" label="Title" filled style="font-weight: bold;"></v-text-field>
                    <div class="d-flex flex-wrap flex-grow-1">
                        <date-picker class="ma-1" v-model="data.start" label="Start"/>
                        <date-picker class="ma-1" v-model="data.end" label="End" v-if="data.end" @input="check_end"/>
                    </div>
                </div>
            </v-container>
            
            <daily-tabs :data="data"></daily-tabs>
           
            <save-publish-buttons   @stash_edit="stash_edit" 
                                    @post_schedule="post_update"
                                    @cancel="go_back"></save-publish-buttons>

        </default-container>
        <v-container v-else class="d-flex" style="width: 100%; height: 100%">
            loading
        </v-container>
    </div>
</template>

<script>
import ActionHeader from '@/components/ActionHeader.vue'
import DatePicker from '@/components/Helper/DatePicker.vue';
import DefaultContainer from '@/components/Helper/DefaultContainer.vue';
import DailyTabs from '@/components/Schedule/DailyTabs.vue';
import SavePublishButtons from '@/components/Schedule/Interactables/SavePublishButtons.vue';
import Loading from './Loading.vue';

export default {
  components: { ActionHeader, DatePicker, DailyTabs, DefaultContainer, SavePublishButtons, Loading },

  data: () => ( {
      selected_tab: 0,
      data: null,
      loading: true,
  }),
  async created() {
      this.data = await this.$service.schedules.get_schedule( this.$route.params.id, this.$route.params.endpoint);
      this.loading = false;
  },
  methods: {
      check_end() {
          this.data.end = Date.parse( this.data.end ) >= Date.parse( this.data.start) ? this.data.end : this.data.start;
      },
      stash_edit() {
          this.$service.schedules.update_repository();
          this.$router.push("/home");
      },
      go_back() {
           this.$service.overlay.open_prefab_overlay({
                name: "cancel_edit_message",
                actions: [
                   {title: "Discard",
                    action: () => {
                        this.$service.schedules.clear_repository( this.$route.params.id, this.$route.params.endpoint )
                        this.$router.push("/home");
                    }
                    },
                    {title: "Save",
                    action: () => {
                        this.stash_edit();
                    }},
                    {title: "Continue",
                    action: () => null},
                ]
            })

      },
     post_update() {         
            this.$service.overlay.open_prefab_overlay({
                name: this.$route.params.id == "new" ? "post_update_message" : "post_create_message",
                actions: [
                    {title: "Publish",
                    action: () => this._post_update() }, 
                    {title: "Cancel",
                    action: () => console.log( "Canceled" )},
                ]
            });
      },
      async _post_update() {
            await this.$service.schedules.post_schedule( this.$route.params.id, this.$route.params.endpoint );
            this.$router.push("/home");
        },
     
  },
}
</script>

<style>

</style>