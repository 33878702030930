<template>
<div class="box">
  <div class="box__inner"></div>
</div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

$triangleHeight: 20px;

.box {
  filter: drop-shadow(0px 3px 0px rgba(0, 0, 0, 1));
 

  &__inner {
  position: relative;
  width: 100%;
  height: 20px;
  background-color: white;

    &:after {
      content: '';
      position: absolute;

      // Position triangle below .box__inner
      // using inverted triangle height
      bottom: $triangleHeight * -1;
      
      // Position triangle in the center
      left: 50%;
      
      // Adjust the horizontal center
      // because the origin for positioning
      // is the top left boundary instead
      // of the center of the element
      transform: translateX(-50%);
      

      width: 0; 
      height: 0; 
      border-left: $triangleHeight solid transparent;
      border-right: $triangleHeight solid transparent;

      border-top: $triangleHeight solid white;
    }
  }
}
</style>