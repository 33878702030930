<template>
  <v-card class="d-flex flex-column py-2 my-2">
        <div class="d-flex justify-space-between mx-5 mt-2">
            <h2>{{base_content.name}}</h2> 
            
            <v-btn @click="$emit('remove', base_content)" icon large color="grey"><v-icon>mdi-delete-forever</v-icon></v-btn>
        </div>
  
        <v-container class="d-flex align-center flex-wrap" >
            <v-text-field elevation="0" hide-details label="Start" readonly filled dense :value="time_range"></v-text-field>

            <v-spacer v-if="!$vuetify.breakpoint.mobile"></v-spacer>

            <div :class="$vuetify.breakpoint.mobile ? 'flex-grow-1' : ''"> 
                <highlighted-button @click="$emit('edit', base_content)"
                    style="height: 50px;"
                    :fill="$vuetify.breakpoint.mobile"
                    text="Edit Schedule" highlight_text="Continue Scheduling" :highlight="stashed" ></highlighted-button> 
                </div>
            </v-container>    
      </v-card>
</template>

<script>
import moment from 'moment';
import HighlightedButton from '../Helper/HighlightedButton.vue';

export default {
  components: { HighlightedButton },
    props: ["base_content", "endpoint"],
    computed: {
        time_range() {
            return this.base_content.end ? `${this.iso_to_murrica(this.base_content.start)} - ${this.iso_to_murrica(this.base_content.end)}`  : this.iso_to_murrica(this.base_content.start);
        },
        stashed() {
            return Boolean( this.$service.schedules.state.repository[this.endpoint][this.base_content.id])
        }
    },
    
    methods: {
        iso_to_murrica(date) {
            const last_time = moment( date, "YYYY-MM-DD" );
            return last_time.format("L");
        },
    }
}

</script>

<style>

</style>