<template>
    <v-container style="max-width: 1200px;">
        <action-header back="true" title="Help"></action-header>
        <v-container >
         <template v-if="$vuetify.breakpoint.mobile">
                <v-menu style="width: 100%;">
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="d-flex align-center">

                            <v-spacer></v-spacer>
                            <h3 v-if="help_data.length" class="ml-2" >{{help_data[selected_tab].title}}</h3>                   
                            <v-spacer></v-spacer>

                            <v-icon right large>
                                mdi-arrow-down-drop-circle
                            </v-icon>
                            

                        </div>
                    </template>

                    <v-list class="grey lighten-3">
                        <v-list-item
                            v-for="(item, index) in help_data"
                            :key="index"
                            @click="selected_tab = index"
                        >
                            {{ item.title }}
                        </v-list-item>
                    </v-list>
            </v-menu>
        </template>

        <v-tabs :vertical="!$vuetify.breakpoint.mobile"
                 v-model="selected_tab"
                 color="black">

                <template v-if="!$vuetify.breakpoint.mobile">

                    <v-list dense style="max-width: 300px;">

                        <v-card elevation="0"
                            class="my-2 mr-8"                           
                            style=" text-transform: none; 
                                    text-align: right;
                                    "
                            v-for="(categorie, index) in help_data" :key="index" 
                            @click="selected_tab = index">
                                <b v-if="selected_tab == index">  {{categorie.title}} </b>
                                <span v-else>  {{categorie.title}} </span>                               
                        </v-card>

                    </v-list>


                        

                </template>

                <v-tab-item v-for="categorie in help_data" :key="categorie.title"> 
                    <div v-for="help_text in categorie.entries" :key="help_text.title">
                        <h1>{{help_text.title}}</h1>
                        <div v-html="help_text.help_text"/>
                        <hr class="colored_hr">           
                    </div>
                </v-tab-item>
        </v-tabs>
        </v-container>
    </v-container>
</template>

<script>
import ActionHeader from '../components/ActionHeader.vue'

export default {
    
  components: { ActionHeader },
  data: () => ( {
          selected_tab : 0,
      }),
  computed: {
      
      help_data() {
          return this.$service.help.state.categories.help_categorie;
      }
  }
   
}
</script>

<style>
.colored_hr {
     height: 1px;
        background-color: #ccc;
        border: none;
}

</style>