<template>
<v-tooltip bottom max-width="300" color="black">
      <template v-slot:activator="{ on, attrs }">
            
            <v-icon v-bind="attrs" class="mx-4" style="background-color: yellow; border-radius: 50%;"
                    v-on="on" >mdi-help-circle-outline</v-icon>
         
      </template>

      <span class="ma-2"><slot></slot></span>

    </v-tooltip>

   
</template>

<script>
export default {

}
</script>

<style>

</style>