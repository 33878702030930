export default{
    state : {
        library : {},
        default : {
            id : -1,
            Title : "Missing"
        }
    },
    async load() {
        await this.services.http.get("items/content")
            .then( response =>{
                for( let element of response.data.data) {
                    this.state.library[ element.id ] = element;
                }
            } )
            .catch( error => this.services.error.push( error ));
    },
    authorized_asset( id ) {
        return `${this.services.http.defaults.baseURL}/assets/${this.state.library[id].Thumbnail}?access_token=${this.services.user.token}`;
    },
    get_content( id ) {
        return this.state.library[id] || this.state.default;
    }

}