<template>
<div class="d-flex align-center justify-center">
    <loading v-if="processing"/>
     <v-btn elevation = "0" @click="logout" text  absolute top right>
              <b>Logout</b>
              <v-icon>mdi-logout</v-icon>
        </v-btn>
    <v-tabs-items v-model="selected_tab" class="text-center">

       

        <v-tab-item >
            <h2>Activate two-factor authentication (2FA)</h2>
            <p>For security reasons, you need to activate 2FA using an authenticator app.</p>
            <p>Download and install on your mobile device one of the following:</p>
           
            <div class="d-flex flex-wrap">
                <v-card outlined class="ma-2 pa-4 flex-grow-1 d-flex flex-column">
                     <v-img aspect-ratio="1" max-height="50" :src="require('@/assets/MicrosoftAuthenticator.png')" contain/>
                    <h2 class="text-center">Microsoft Authenticator</h2>
                        
                        <v-btn href="https://play.google.com/store/apps/details?id=com.azure.authenticator" target="_blank" text block class="text-caption"> 
                            <v-icon >mdi-google-play</v-icon>
                           PlayStore</v-btn>
                        <v-btn href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458" target="_blank" text block class="text-caption"> 
                            <v-icon >mdi-apple</v-icon>
                            AppStore</v-btn>
                   
                </v-card>
            <v-card outlined  class="ma-2 pa-4 flex-grow-1 d-flex flex-column justify-center">
                    <v-img aspect-ratio="1" max-height="50" :src="require('@/assets/GoogleAuthenticator.png')" contain/>
                    <h2 class="text-center">Google Authenticator</h2>
                        <v-btn href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank" text block class="text-caption">
                            <v-icon >mdi-google-play</v-icon>
                            PlayStore</v-btn>
                        <v-btn href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank" text block class="text-caption"> 
                            <v-icon >mdi-apple</v-icon>
                            AppStore</v-btn>
                   
                </v-card>
            </div>
           <p>After setting the app up, continue to the next page.</p>
           
            <v-btn text @click="selected_tab += 1" block outlined>Continue</v-btn>

        </v-tab-item>
        <v-tab-item>
            <p>Please hold your phone with the authenticator ready and enter your password!</p>
            <v-text-field
                    outlined 
                    type="password"
                    v-model="password" 
                    @input="valid_password = true"
                    label="Password"   
                    :rule="() => valid_password" />
            <v-btn text @click="request" block :disabled="!password" outlined>Request</v-btn> 
           
        </v-tab-item>
        <v-tab-item class="d-flex flex-column justify-center">
            <p>Scane the QR-Code with your Authenticator-App and enter the One Time Password below</p>

            <qrcode-vue :value="mfa_secrets.otpauth_url" 
                        v-if="mfa_secrets" 
                        class="d-flex justify-center ma-4" 
                        size="300"
                        :href="mfa_secrets.otpauth_url"></qrcode-vue>

            <v-text-field
                    outlined 
                    v-model="otp"
                    label="OTP"   
                    :rule="() => valid_otp" />
            <v-btn text @click="activate" block :disabled="!otp" outlined>Activate</v-btn> 
           
        </v-tab-item>

    <div class="d-flex justify-space-between">
     
        
    </div>
    </v-tabs-items>
   
</div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import Loading from './Loading.vue'
export default {
  components: { Loading, QrcodeVue },
  mounted() {
      this.$service.user.check_tfa();
  },
    
    data : () => ( {
        selected_tab : 0,
        password : "",
        otp : "",
        processing : false,
        valid_password : true,
        valid_otp : true,
        mfa_secrets : null
    }),
    methods: {
        logout() {
            this.$service.overlay.open_prefab_overlay({
                name: "logout_message",
                actions: [
                    {title: "Logout",
                    action: () => this.$service.user.logout()},
                    {title: "Cancel",
                    action: () => console.log( "Canceled" )},
                ]
            })
        },
        async request() {
            this.processing = true;
            await this.$service.http.post("/users/me/tfa/generate", { password:this.password })
                .catch(() => this.valid_password = false)
                .then( response => this.mfa_secrets = response.data.data );

            this.processing = false;
            if (this.mfa_secrets) { this.selected_tab +=1; }
        
        }  ,
        async activate() {
            this.processing = true;
            await this.$service.user.enable_tfa( this.otp, this.mfa_secrets.secret)
                .then( async () => {
                    await this.$service.user.load_user_data();
                    this.$router.push("/home") } )
                .catch((error) => {
                    this.valid_otp = false;
                    this.$service.overlay.open_prefab_overlay({name:"invalid_2fa_activate"});
                    console.log( error );
                    this.otp = "";
                });
            this.processing = false;
        }
    }
}
</script>

<style>

</style>