import io from "socket.io-client";

export default {
    state: {
        connected : false
    },
    socket : null,
    load() {
        const options = { withCredentials: true,
                        transportOptions: {
                            polling: {
                                extraHeaders: {
                                    ...this.services.http.defaults.headers.common
                                }
                            } 
                        }};
       
        this.connection = io( this.services.http.defaults.baseURL, options);

        this.connection.on("connect", (socket) => {
            console.log("SocketIO-Connection Created;")
            this.state.connected = true;
            this.socket = socket;
           
        });
        this.connection.on("disconnect", () => {
            this.state.connected = false;
            this.socket = null}
        );
        this.connection.on("Status", () => {
            this.services.status.load()} 
        );
        
    },
    emit( data ) {
        console.log("Eimitting broadcast", data);
        this.connection.emit("_broadcast", data);
   
    }
    
}