<template>
  <v-container>
        <v-container class="d-flex align-center">
            <h1>{{title}}</h1>
            <slot></slot>
            <v-spacer></v-spacer>
            
            <highlighted-button 
              style="height: 40px;" 
              @click="$router.push(`/create/${endpoint}/new`)"
              text="Add +"
              highlight_text="Continue +"
              :highlight="creation_stashed"></highlighted-button>
        
      </v-container> 
      
      <div v-if="$service.schedules.state.running[endpoint][0]">
        <h3>Running</h3>
        <schedule-card :base_content="$service.schedules.state.running[endpoint][0]"
          @edit="edit"
          @remove="remove_schedule"
          :endpoint="endpoint"></schedule-card>
          <br>
      </div>
        
        
      <h3>Upcoming</h3>
      <schedule-card v-for="base_content in $service.schedules.state.upcoming[endpoint]" :key="base_content.id" 
        :base_content="base_content"
        @edit="edit"
        @remove="remove_schedule"
        :endpoint="endpoint"></schedule-card>
  </v-container>
</template>

<script>
import HighlightedButton from '../Helper/HighlightedButton.vue';

import ScheduleCard from './ScheduleCard.vue';
export default {
  props: ["title", "endpoint", "limit_end"],
  components: { ScheduleCard, HighlightedButton},
  computed: {
    creation_stashed() {
        return Boolean( this.$service.schedules.state.repository[this.endpoint]["new"])
      }
    },
  
    methods: {
      edit: function(data) {
        this.$router.push(`/edit/${this.endpoint}/${data.id}`);
      },
     async remove_schedule(data) {
       this.$service.overlay.open_prefab_overlay( 
         {      name: "remove_schedule_message",
                color: "warning",
                actions: [
                    {title: "Ok",
                    action: () => this.$service.schedules.delete( data.id, this.endpoint)},
                    {title: "Cancel",
                    action: () => console.log( "Action Canceled" )},
                ] });
     },
      async load_data() {
        await this.$service.schedules.load_upcoming( this.endpoint );
        await this.$service.schedules.load_running( this.endpoint, this.limit_end );
      }
    },
    created: async function() {
        await this.load_data();
    }
}
</script>

<style>

</style>