<template>
    <div  class="flex-grow-1" style="width: 150px;">
        <v-menu ref="menu" 
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  :offset-overflow="true"
                  min-width="290px"
                  :return-value.sync="date" 
                
      >

        <template v-slot:activator="{ on, attrs }">
          <v-text-field :value="iso_to_murrica" 
                        filled
                        :label="label" 
                        readonly v-bind="attrs" 
                        v-on="on"></v-text-field>
        </template>

        <v-date-picker
          v-model="date"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="update">OK</v-btn>
        </v-date-picker>
      </v-menu>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    props: ["value", "label", "fill"],
    methods: {
        update() {
            this.$refs.menu.save(this.date);
            this.$emit("input", this.date);
        }
    },
    computed: {
      iso_to_murrica() {
        const last_time = moment( this.value, "YYYY-MM-DD" );
        return last_time.format("L");
      },
    },
    data: function() {
        return {
            date: this.value,
            menu: false,
        }
    },
}
</script>

<style>

</style>