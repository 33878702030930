<template>
<base-help-button>The Special Schedule lets you program content for a single day.<br> A single day starts in the evening and continues until the next morning.<br> You can have the Special Schedule run for one or more days. The Weekly Schedule will resume after the period for the Special Schedule has end</base-help-button>

   
</template>

<script>
import BaseHelpButton from './BaseHelpButton.vue'
export default {
  components: { BaseHelpButton },

}
</script>

<style>

</style>