<template>
  <div class="d-flex align-center justify-center" >

      <v-card style="width: 450px;" class="d-flex flex-wrap align-center pa-1"> 
            <div  v-if="$vuetify.breakpoint.mobile"  style="width: 100%;" class="d-flex justify-end">
                 <v-btn @click="remove_entry" icon x-large color="grey"><v-icon>mdi-delete-forever</v-icon></v-btn>
            </div>

            <content-edit-card :size="90" :data="data" class="flex-grow-1" style="width: 300px;"></content-edit-card>
            
            <time-picker label="Start"   
                         v-model="data.start" 
                         @change="sort_entries"                        
                         :disabled = "!Boolean(index)"  
                         :warning="!Boolean(index)"></time-picker>  
            
            
      </v-card>

     <v-btn v-if="!$vuetify.breakpoint.mobile" @click="remove_entry" icon x-large color="grey"><v-icon>mdi-delete-forever</v-icon></v-btn>

  </div>
</template>

<script>
import ContentEditCard from '../Content/ContentEditCard.vue'
import TimePicker from '../Helper/TimePicker.vue';
export default {
    methods: { 
        remove_entry() {
            this.$emit('remove_entry', this.index);
        },
        sort_entries() {
            this.$emit('sort_entries');
        },
     },
    
    components: { ContentEditCard, TimePicker },
    props: ["data", "index"]
}
</script>

<style>

</style>