<template>
  <v-overlay :value="$service.overlay.state.active">



        <v-card :color="$store.state.overlay.color" style="width: 300px" class="flex-grow-1">
          <div class="d-flex flex-column align-center">
            <v-icon   class="ma-5" >{{$service.overlay.state.icon}}</v-icon>
            <h3 style="text-transform: uppercase" class="ma-1">{{$service.overlay.state.title}}</h3>
            
          </div>
          <div class="mx-4" >
            <div v-html="$service.overlay.state.message"/>
          </div>
          <v-card-actions class="d-flex justify-space-around"> 
            <v-btn text v-for="action in $service.overlay.state.actions" :key="action.title" @click="click(action.action)">{{action.title}}</v-btn>
          </v-card-actions>
        </v-card>


  </v-overlay>
</template>

<script>

export default {
  components: { },
  methods: {
    click(action_function) {
      this.$service.overlay.close_overlay();
      action_function();
    },
  }
}
</script>

<style>

</style>