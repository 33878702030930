const prefabs = require("./prefab_messages.json");

export default {
    state : {
        active  : false,
        title   : 'Attention',
        message : '',
        icon    : "mdi-alert",
        actions: [
          { title: "Ok",
            action: () => console.log( "Overlay" ) }
          ]
      },

    close_overlay() {
        this.update_store( {active : false });
    },
    open_prefab_overlay(data) {
      console.log( data );
      Object.assign( data,  prefabs[data.name] || {})
      console.log( data );
      this.open_overlay( data );
    },
    open_overlay(data) {
        let overlay_data = {
                          active: true,
                          icon    : "mdi-alert",
                          title: 'Attention',
                          message: '',
                          color: "",
                          actions: [
                            { title: "Ok",
                              action: () => console.log( "Overlay" ) }
                            ]
                        };
       Object.assign( overlay_data, data);
       this.update_store( overlay_data );
       console.log( this.state );
      }
}