<template>
  <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        :offset-overflow="true"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }" >
         
          <v-text-field
            :disabled="disabled"
            :style="button_styling"
            class="flex-grow-1"
            hide-details
            
            :value="am_pm_time"
            :label="label"
            :prepend-icon="prependIcon"
            readonly
            v-bind="attrs"
            v-on="on"
          >
             <template  v-if="warning" v-slot:append>
              <help-button-schedule-start></help-button-schedule-start>
             </template>
           </v-text-field>
           </template>

          <v-time-picker v-if="menu" v-model="time">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="update">
              Set
            </v-btn>
          </v-time-picker>
      </v-menu>
</template>

<script>
import moment from 'moment';
import HelpButtonScheduleStart from '../HelpButtons/HelpButtonScheduleStart.vue';

export default {
  components: { HelpButtonScheduleStart },
    props: ["label", "value", "warning", "prependIcon", "disabled", "notification"],
    methods: {

      update() {
        this.$refs.menu.save(this.time);
        this.$emit("input", `${this.time}:00.000`);
        this.$emit("change", `${this.time}:00.000`);
       
      }
    },
    computed: {
      button_styling() {
        return {
          height      : "100%",
          width       : "120px",
          "text-align" : "center"
        }
      },
      am_pm_time() {
        const last_time = moment( this.value, "HH:mm:ss.SSS" );
        return last_time.format("LT");
      },
      am_pm_default() {
        const last_time = moment( this.$store.state.timing_data.start_time, "HH:mm:ss.SSS" );
        return last_time.format("LT");
      }
    },
    watch: {
      time() {
        this.display_time = this.am_pm_time;
      }
    },
    data: function() {
      return {
        menu: false,
        time: this.value ? `${this.value.split(':')[0]}:${this.value.split(':')[1]}` : '',
      }
    },
}
</script>

<style>

</style>