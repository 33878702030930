
export default {
    state: {
        categories: []
    },
    async load() {
        this.services.http.get( "items/Help" )
            .then( response => this.state.categories = response.data.data )
            .catch( error => this.services.error.push(error) );
   
    }
}