<template>
  <div style="width: 100%;">
      <action-header :back="true" :title="this.$route.name"></action-header>
      <div class="my-16 align-center" style="height=100%">


        <v-card style="max-width: 500px;" class="ma-auto pa-6" block flat>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field outlined v-model="email" label="E-Mail" :rules="[v => Boolean(v)  || 'Please enter your E-Mail', ]"></v-text-field>
                <v-btn @click="reset_password" 
                        block 
                        :disabled="!valid" 
                        dark large elevation = "0">Request new Password</v-btn>
            </v-form>
        </v-card>
      </div>
  </div>
</template>

<script>
import ActionHeader from '../components/ActionHeader.vue'
export default {
  components: { ActionHeader },
    data : () => ( {
        email : '',
        valid: false,
    }),
    methods: {
        async reset_password() {
            this.$service.user.request_password_reset(this.email);
            this.$service.overlay.open_overlay( {
                title: "Resetlink Sent",
                message: "We sent a Link to your email with a Link to reset your password.",
                actions: [ {title: "Login",
                            action: () => this.$router.push("/login") }]
            });
                
           
        } 
    }
}
</script>

<style>

</style>