<template>
    <div style="min-height: 100%; width: 100%; max-width: 500px;" class =" d-flex justify-center align-center">

        <v-container>

            <v-img max-height="155" contain 
            :src="$service.images.public_url($service.pagesettings.state.data.Header_Image)"></v-img>

        <v-card class="pa-6" block flat>
            <v-form v-model="valid" lazy-validation ref="form">
                <v-text-field 
                    outlined
                    v-model="login_data.email" 
                    label="E-Mail"
                    :rules="[() => valid || 'Check Mail or Password']"></v-text-field>

                <v-text-field
                    outlined 
                    type="password"
                    v-model="login_data.password" 
                    label="Password"
                    :rules="[() => valid || 'Check Mail or Password']"/>

                 <v-text-field
                    v-if="$service.user.state.require_otp"
                    outlined 
                    type="password"
                    v-model="login_data.otp" 
                    label="OTP"
                    :rules="[() => valid || 'Check OTP']"></v-text-field>

                <a center @click="$router.push('/forgot-password')">Forgot Password</a>

                <v-btn  @click="login" 
                        elevation="0" 
                        block
                        large
                        dark>Login</v-btn>
            </v-form>
            <v-spacer></v-spacer>
        

        <v-overlay absolute :value="loading" >
            <v-progress-circular indeterminate >Login...</v-progress-circular>
        </v-overlay>
        </v-card>
        </v-container>
    </div>
</template>

<script>
export default {
    data: () => ( {
        loading: false,
        valid: true,
        login_data: {
            email: '',
            password: '',
            otp : "",
        },
       
    }),

    watch: {
        valid: function() { this.$refs.form.validate();}
    },
    mounted() {
        this.login_data = { email:"", password:"", otp: "" }
    },
    methods: {
        reset_validation: function() { this.valid = true;},

        login: async function() {
            this.valid = true;
            const payload = {
                email : this.login_data.email,
                password : this.login_data.password
            }
            if (this.login_data.otp){ payload.otp = this.login_data.otp}

            await this.$service.user.auth( payload )
                .then( () => {
                    if (this.$service.user.state.valid) {
                        this.$router.push("/home");
                    }
                   } )
                .catch( () => this.valid = false );
            
        }
    }
}
</script>