<template>
<base-help-button>The Weekly Schedule lets you program weekly content that will repeat over a period.<br> The Weekly Schedule will always recur on a weekly basis unless overridden by a Special Schedule</base-help-button>

   
</template>

<script>
import BaseHelpButton from './BaseHelpButton.vue'
export default {
  components: { BaseHelpButton },

}
</script>

<style>

</style>