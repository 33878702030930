<template>
  <v-container style="width:100%;">
      <div class="d-flex align-center justify-space-between">
            <slot></slot>
            
            <highlighted-button text="+ ADD" :capitalize="true" @click="add_entry" style="height: 40px"></highlighted-button>
        </div>
        
        <transition-group name="flip-list" tag="ul">
           
            <schedule-edit-card class="py-1" :size="25" v-for="(day_data, index) in data.actions" 
                                :key="day_data.id ? day_data.id :  day_data.placeholder_id" 
                                :index="index" 
                                :data="day_data" 
                                @remove_entry="remove_entry"
                                @sort_entries=" $service.helper.sort_actions( data.actions ) ">
            </schedule-edit-card>
        </transition-group>

  </v-container>
</template>

<script>
import ScheduleEditCard from './ScheduleEditCard.vue'
import moment from 'moment';
import HighlightedButton from '../Helper/HighlightedButton.vue';

export default {
    methods: {
        remove_entry(index) {
            this.data.actions.splice(index, 1);
        },

        add_entry() {
            let last_time = this.data.actions[ this.data.actions.length -1] ? 
                            moment( this.data.actions[ this.data.actions.length -1].start, "HH:mm:ss.SSS" ) : 
                            moment( this.$service.installation.state.data.start_time, "HH:mm:ss.SSS" );

            last_time.add( 30, "minutes");

            let new_entry = {
              placeholder_id: Math.random(),
              content: this.$service.pagesettings.state.data.default_start_content ,
              start: last_time.format("HH:mm:ss.SSS"),
            }
            this.data.actions.push(new_entry);
        },
    },
    components: { ScheduleEditCard, HighlightedButton },
    props: ["data"],
}
</script>

<style>
.flip-list-move {
  transition: transform 1s;
}
</style>