<template>
   <v-card flat outlined  class="flex-grow-1">   
      
        <div v-if="!data.now_playing" > 
            <v-card-title>No Content Playing</v-card-title>
        </div>
        <div v-else class="d-flex">

                    <v-img v-if="$vuetify.breakpoint.mobile" :src="thumbnail" rounded aspect-ratio="0.6" :min-width="120" :max-width="120" ></v-img>

                    <div class="ma-auto d-flex flex-column">
                        <span class="font-weight-medium">Currently Playing:</span>
                        <p class="text-lg-h5">{{content.Title}}</p>
                        <h5>Next</h5>
                        <span>{{up_next_title}}</span>
                        <span>{{am_pm_time}}</span>
                    
                    </div>
       
                   
                    <v-img v-if="!$vuetify.breakpoint.mobile" :src="thumbnail" rounded aspect-ratio="1" :min-width="200" :max-width="200" ></v-img>
          
                   
           </div>
        
    </v-card>
    
</template>

<script>

export default {
    props : ["data"],
    computed: {
       
        content() {
            return this.$service.content.state.library[this.data.now_playing] ;
        },
        up_next_title() {
            return this.data.up_next ? this.$service.content.state.library[this.data.up_next].Title : '';
        },
        thumbnail() {
            return this.$service.content.authorized_asset( this.data.now_playing );
        },

      am_pm_time() {
        if ( ! this.data.time_end ) { return "End of Schedule"; }
        const zeroPad = (num, places) => String(num).padStart(places, '0')
        let splitted_time =  this.data.time_end.split(":");
        if (splitted_time.length <= 1) {
             return this.data.time_end;
        }
        let parsed_hour = parseInt(  splitted_time[0] );
        let parsed_minute = parseInt( splitted_time[1] );
        let am_pm = parsed_hour > 12 ? "PM" : "AM";
    
       
        return  `${zeroPad( parsed_hour%12, 2)}:${zeroPad( parsed_minute, 2)} ${am_pm}`;

      }
    },
}
</script>

<style>

</style>