<template>
  <v-card flat class="flex-grow-1 ma-1">
      <content-selector-menu @select="select" >
        
        <v-card :height="size" class="d-flex align-center" outlined rounded >
         
          <v-img :src="$service.content.authorized_asset(data.content)" rounded aspect-ratio="1" :min-width="size" :max-width="size" ></v-img>

          <b class="mx-2">{{$service.content.state.library[data.content].Title}}</b>
          <v-spacer></v-spacer>
          <v-icon class="mx-2">mdi-menu-swap</v-icon>
        </v-card>
   
      </content-selector-menu>

  
    
  </v-card>
</template>

<script>
import ContentSelectorMenu from './ContentSelectorMenu.vue';
export default {
  components: { ContentSelectorMenu },
  props: { data: null,
           size: {default: 100}
          },

  methods: {
    select( payload ) {
      this.data.content = payload;
    }
  }
}
</script>

<style scoped>
</style>