<template>
<base-help-button>The start time for the initial content is automatically set.</base-help-button>

   
</template>

<script>
import BaseHelpButton from './BaseHelpButton.vue'
export default {
  components: { BaseHelpButton },

}
</script>

<style>

</style>