
const axios = require("axios");

//axios.defaults.baseURL = process.env.NODE_ENV == 'production' ? window.location.origin : "https://api.sandbox.amb-service.net";
axios.defaults.baseURL = `${window.location.protocol}//api.${window.location.hostname}`;
axios.defaults.withCredentials = true;

axios.prefix_url = function( url ) {
    const prefix = !url.startsWith("http") ? this.defaults.baseURL : '';
    return  prefix + url;
};

axios.set_auth = function( token ) {
    this.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

axios.redirect = function( url ) {
    if (url) { window.location.href = url; }
};

export default axios;