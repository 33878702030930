<template>
  <v-menu top 
  :close-on-content-click="false"
  v-model="open">
      <template v-slot:activator="{ on, attrs }">
       <v-sheet v-bind="attrs" v-on="on" plain>
       <slot style="flex-grow: 1;">
        </slot>
       </v-sheet>
      </template>

      <v-list>
          <v-text-field class="ma-2" clearable label="Search" v-model="search_term"></v-text-field>
            <v-list-item v-for="(item, index) in filtered_list" :key="index" class="justify-space-around"
                @click="select(index)">
                <v-list-item-title>{{item.Title}}</v-list-item-title>
                <v-img :height="30" :width="30" :src="$service.content.authorized_asset(item.id)"></v-img>
            </v-list-item>
      </v-list>
    </v-menu>
</template>

<script>
export default {
    data: () => ({
        open : false,
        search_term : '',
    }),
    computed: {
        filtered_list: function() {
            if (!this.search_term) { return this.$service.content.state.library; }
            
            return Object.fromEntries(Object.entries(this.$store.state.content_library).
                filter(([index]) => {
                    return this.$store.state.content_library[index].Title.toLowerCase().includes(this.search_term.toLowerCase());
                    }));
        }
    },
    methods: {
        select: function(index) {
            this.$emit("select", index);
            this.search_term = '';
            this.open = false;
        }
    }
}
</script>

<style>

</style>