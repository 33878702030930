import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    socket_connection: null,
    login_user: null,
    page_settings: null,
    content_library: {},
    timing_data: {
      custom_timing : false,
      start_time: '',
      end_time: '', 
    },


    stashed_edits: {
      "base-content-schedules": {},
      "special-content-schedules": {},
    },
  },
  mutations: {
    set_socket_connection( state, data){
      state.socket_connection = data;
    },
    stash_edit(state, data) {
      state.stashed_edits[data.type][data.id] = data.data;
    },
    clear_stash(state, data) {
      console.log( "Deleting", data);
      console.log( state.stashed_edits[data.type][data.id] );
      delete state.stashed_edits[data.type][data.id];
      console.log( state.stashed_edits[data.type][data.id] );
    },
    
    update_timing_data(state, data) {
      state.timing_data = data;
    },

    
    update_content_library(state, data_list){
      state.content_library = {};
      
      for (let content of data_list){
        
        state.content_library[ content.id ] = content;
      }
    },
    set_page_data(state, data) { 
      state.page_settings = data;
    },
    set_user_data(state, data) {
      state.login_user = data;
    },
    
  },
  actions: {
  },
  modules: {
  }
})
