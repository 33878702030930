<template>
  <div class="d-flex px-4 flex-wrap">
            <v-btn outlined
                :class="button_classes"
                 @click="$emit('stash_edit')">
                 Save & Continue later</v-btn>

            <v-btn outlined
                :class="button_classes"
                @click="$emit('cancel')">
                Cancel</v-btn>

            <v-btn outlined
                style=" color: white; background-color: black"
                :class="button_classes"
                @click="$emit('post_schedule')">
                Publish schedule</v-btn>

  </div>
            
</template>

<script>

export default {
    data: () => ( {
        "button_classes" : "flex-grow-1 my_button ma-1",
        
    })
}
</script>

<style scoped>
.my_button {
    width: 300px;
    height: 50px;
}
</style>