<template>
   <v-card :color="system_levels[String(status.health)].color" flat outlined  class="flex-grow-1" >
        
      <div v-if="status" class="d-flex align-left flex-column justify-center pa-4"> 
          <div class="connection_state" :style="indicator_style"/>
         
        <p>System Performance</p>
        <p style="color:white;" class="headline d-flex">
            <span class="text-h4"><b>Projector</b> 
            <v-chip color="black" text-color="white" class = "mx-6 pa-4" v-if="status.projector"> <h2><b>On</b></h2> </v-chip>
            <v-chip color="black" text-color="white" class = "mx-6 pa-4" v-else> <h2><b>Off</b></h2> </v-chip>
            </span>
        </p>

        <p><b>Mode: </b>{{status.mode}}</p>
        <p><b>System Performance:</b> {{system_levels[String(status.health)].message}}</p>
        
 
       
      </div>
   </v-card>
</template>

<script>


export default {
    computed: {
        indicator_style() {
            return { "background-color" : this.$service.socketio.state.connected ? "lightgreen" : "lightslategray"}
        }
    },
    props: ["status"],
    data: () => ( {
        color : "grey",
        message: "",
        status_message: '',
        system_levels : {
            "0": {color: "success",   message:   "Working Well"},
            "1": {color: "warning",   message:   "Needs Attention"},
            "2": {color: "error",     message:   "Critical Issue"},
            "-1": {color: "grey",     message:     "Not Connected"}
        },
    }),
    watch: {
        
    }
}
</script>

<style>
.connection_state {
    width: 12px;
    height: 12px;border-radius: 100%;
    position: absolute;
    right: 4px;
    top: 4px;

}
</style>