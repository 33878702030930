import default_factory from "./default_factory";

export default {
    state: {
        upcoming:{
            Base_Content_Schedule : [],
            Special_Content_Schedule: []
        },
        running:{
            Base_Content_Schedule : {},
            Special_Content_Schedule: {}
        },
        repository: {
            Base_Content_Schedule : {},
            Special_Content_Schedule : {}
        }
    },
    
    fields : [ "id","name","start","end","actions.name","actions.start","actions.actions.id","actions.actions.start","actions.actions.content"],
    async load() {
        const repo_data = this.services.user.state.user_data.repository;
        for( const [key, value] of Object.entries(repo_data) ) {
            this.state.repository[key] = value;
        }
    },
    update_repository() {
        this.services.user.update( {repository : this.state.repository });
    },
    async load_upcoming( endpoint ) {
        const params = {
            "filter[start][_gt]" : this.services.helper.today_iso(),
            "sort" : "start"
        }
        await this.services.http.get(`/items/${endpoint}`, { params : params })
            .then( response => {
                    this.state.upcoming[endpoint] = response.data.data;
                } )
            .catch( error => console.log(error))
       
    },
    async load_running( endpoint , limit_end = false) {
        const params = {
            "filter[start][_lte]" : this.services.helper.today_iso(),
            "limit" : 1,
            "sort" : "-start"
        };
        if ( limit_end ) { params["filter[end][_gt]"] = this.services.helper.today_iso( - (24+12) ) }

        await this.services.http.get( `/items/${endpoint}`, {params : params } )
            .then( response =>this.state.running[endpoint] = response.data.data )
            .catch( error => console.log(error))
       
    },
    async _load_schedule( id, endpoint ) {
        if (id =="new" ) {
            this.state.repository[endpoint][id] = default_factory[endpoint]();
        } else {
           await this.services.http.get(`/items/${endpoint}/${id}?fields=${this.fields.join(",")}`)
                             .then( response => { this.state.repository[endpoint][id] = response.data.data } );
       }
       return this.state.repository[endpoint][id];
    },
    async get_schedule( id, endpoint ) {
        return this.state.repository[endpoint][ id ] || await this._load_schedule( id, endpoint);                                              
    },
    clear_repository(id, endpoint) {
        delete this.state.repository[endpoint][id];
        this.update_repository();
    },
    async post_schedule(id, endpoint) {
        if (id=="new") { await this.create_schedule(id, endpoint )}
        else { await this.update_schedule(id, endpoint) }
    },
    async update_schedule(id, endpoint) {
        await this.services.http.patch( `/items/${endpoint}/${id}`, this.state.repository[endpoint][id] )
            .then( response => this.state.repository[endpoint][id] = response.data.data )
            .catch( error => this.services.error.push(error) );
        this.clear_repository(id, endpoint);
    },
    async delete( id, endpoint) {
        await this.services.http.delete( `/items/${endpoint}/${id}`)
            .catch( error => this.services.error.push(error) );
        this.load_running(endpoint);
        this.load_upcoming(endpoint);
       
    },
    async create_schedule(id, endpoint) {
        const new_schedule = await this.services.http.post( `/items/${endpoint}`, this.state.repository[endpoint]["new"] )
                                .then( response => response.data.data )
                                .catch( error => this.services.error.push( error ));
        if(!new_schedule) { return; }
        //this.state.repository[endpoint][new_schedule.id] = new_schedule;
        this.clear_repository(id, endpoint);
        
    }
}