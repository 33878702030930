import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import service from './service';

Vue.config.productionTip = false

Vue.prototype.$axios = require( "axios" );
Vue.use( service, {store: store, router: router});

Vue.directive('numbers-only', {
  twoWay: true, // this transformation applies back to the vm
  bind: function () {
    this.handler = function () {
      this.set( this.el.value.replace(/[^\d]/g,'') );
    }.bind(this);
    this.el.addEventListener('input', this.handler);
  },
  unbind: function () {
    this.el.removeEventListener('input', this.handler);
  }
});


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
